<template>
  <div class="flix-well">
    <div class="flix-form-group">
      <input type="text" class="flix-form-control" v-model="name" spellcheck="false" :placeholder="$tc('message.title', 1) + ' / ' + $tc('message.organisation', 1)">
      <small>Pflichtfeld</small>
    </div>
    <div class="flix-form-group">
      <calendarList :callback="setCalendar" />
    </div>
    <div class="flix-form-group" v-if="calendar.length && name">
      <a href="#" class="flix-btn flix-btn-primary" @click.prevent="setOrganisation">{{ $t('message.save', {name: $tc('message.organisation', 1)}) }}</a>
    </div>
    <div class="flix-form-group" v-if="error">
      <div class="flix-text flix-text-danger"><flixIcon id="remove" /> Name ist schon belegt</div>
    </div>
    <div class="flix-form-group" v-if="success">
      <div class="flix-alert flix-alert-success">{{ $t('message.successfullSaved') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    calendarList () { return import('./calendarList') }
  },
  props: {
    callback: {
      type: Function,
      default () { return function () {} }
    }
  },
  data () {
    return {
      name: '',
      variables: this.$getUserVariables(),
      error: '',
      success: false,
      calendar: []
    }
  },
  computed: {

  },
  mounted () {

  },
  watch: {
    name () { this.error = false }
  },
  methods: {
    setCalendar (cal) {
      this.calendar = cal
    },
    setSuccess (ret) {
      this.error = false
      this.success = true
      this.$store.commit('setOrganisations', { data: false })
      setTimeout(function () {
        this.$store.dispatch('getOrganisations', function () { this.$router.push('/dashboard/permissions/organisations/list.html') }.bind(this))
      }.bind(this), 2000)
    },
    createOrganisation () {
      this.checkOrganisation()
    },
    checkOrganisation (ret) {
      this.error = false
      if (ret.data[0]) {
        this.saveOrganisation()
      } else {
        this.error = true
      }
    },
    saveOrganisation () {
      this.$flix_post({
        url: 'organisation/save_new',
        data: {
          user: this.variables.user.md5_id,
          name: this.name,
          calendar: JSON.stringify(this.calendar)
        },
        callback: this.setSuccess
      })
    },
    setOrganisation () {
      if (!this.name.trim() || !this.calendar.length) {
        return false
      }
      this.$flix_post({
        url: 'organisation/check_name',
        data: {
          user: this.variables.user.md5_id,
          name: this.name
        },
        callback: this.checkOrganisation
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
